/* tslint:disable: no-duplicate-imports */
import MsDyn365 from '@msdyn365-commerce/core';
import * as React from 'react';
interface INotificationItemProps {
    id?: number | string;
    message?: string;
    status?: string;
    expiry: number | undefined;
    onDismiss?(index: number | string | undefined): void;
}

export const NotificationItem: React.FC<INotificationItemProps> = (props: INotificationItemProps) => {
    React.useEffect(() => {
        MsDyn365.isBrowser &&
            setTimeout(() => {
                _disMissMessage();
            }, props.expiry && props.expiry * 1000);
    }, []);

    const _disMissMessage = () => {
        if (props.onDismiss) {
            props.onDismiss(props.id);
        }
    };

    const notificationClass = props.status === 'SUCCESS' ? props.status.toLowerCase() : 'error';
     // VSI Customization - {#52133} - Added TabIndex only
    return (
        <div className={`msc-notification msc-notification${props.status ? `--${notificationClass}` : ''}`}>
            <div tabIndex={0} id='notificationMessageId' className='msc-notification__message' aria-live='assertive' role='alert'>{props.message}</div>
            <span className='msc-notification__close' role='button' onClick={_disMissMessage}>
                &times;
            </span>
        </div>
    );
};
